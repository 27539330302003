// src/components/EditAppointment.jsx

import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import IMask from 'imask';
import { IMaskInput } from 'react-imask';
import { Button, TextField } from '@mui/material';

dayjs.extend(customParseFormat);

// Компонент для маскированного ввода даты
const DateMaskInput = React.forwardRef(function DateMaskInput(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00.00.0000"
      definitions={{
        0: /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const EditAppointment = () => {
  const { doctorId, appointmentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [appointment, setAppointment] = useState(
    location.state?.appointment || null
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!appointment) {
      fetchAppointment();
    }
  }, [appointment]);

  const fetchAppointment = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/doctors/${doctorId}`
      );
      const doctor = response.data;

      const foundAppointment = doctor.appointments.find(
        (appt) => appt.id === appointmentId
      );

      if (foundAppointment) {
        setAppointment({ ...foundAppointment, doctorName: doctor.name });
      } else {
        alert('Запись не найдена');
        navigate('/manage-appointments');
      }
    } catch (error) {
      console.error('Ошибка при получении записи на прием:', error);
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setAppointment({ ...appointment, [name]: value });
    // Очистка ошибок при изменении полей
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleSaveAppointment = async () => {
    // Валидация даты
    const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
    if (!datePattern.test(appointment.date)) {
      setErrors({ date: 'Введите дату в формате ДД.ММ.ГГГГ' });
      return;
    }

    // Проверка, является ли дата корректной
    const isValidDate = dayjs(appointment.date, 'DD.MM.YYYY', true).isValid();
    if (!isValidDate) {
      setErrors({ date: 'Введите корректную дату' });
      return;
    }

    try {
      const updatedAppointment = {
        ...appointment,
        date: appointment.date, // Дата уже в формате 'ДД.ММ.ГГГГ'
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/doctors/${doctorId}/appointments/${appointmentId}`,
        { appointment: updatedAppointment }
      );

      alert('Запись успешно обновлена');
      navigate('/manage-appointments');
    } catch (error) {
      console.error('Ошибка при обновлении записи:', error);
      alert('Ошибка при обновлении записи');
    }
  };

  return appointment ? (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Редактировать запись</h2>
      <div className="mb-4">
        <label className="block text-sm mb-2">Врач:</label>
        <p>{appointment.doctorName}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm mb-2">Дата:</label>
        <TextField
          name="date"
          value={appointment.date}
          onChange={handleEditChange}
          placeholder="ДД.ММ.ГГГГ"
          InputProps={{
            inputComponent: DateMaskInput,
          }}
          error={Boolean(errors.date)}
          helperText={errors.date}
        />
      </div>
      {/* Остальные поля формы */}
      <div className="mb-4">
        <label className="block text-sm mb-2">Время:</label>
        <TextField
          type="time"
          name="time"
          value={appointment.time}
          onChange={handleEditChange}
          error={Boolean(errors.time)}
          helperText={errors.time}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm mb-2">Пациент:</label>
        <TextField
          name="name"
          value={appointment.name}
          onChange={handleEditChange}
          error={Boolean(errors.name)}
          helperText={errors.name}
        />
      </div>
      {/* ... */}
      <div className="flex justify-between gap-3">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/manage-appointments')}
        >
          Отменить
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveAppointment}
        >
          Сохранить
        </Button>
      </div>
    </div>
  ) : (
    <p>Загрузка данных...</p>
  );
};

export default EditAppointment;
