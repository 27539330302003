// src/components/License.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '../subcomponents/ImageModal';
// import Loading from '../subcomponents/Loading';
import { Skeleton } from '@mui/material';

const Loader = () => (
  <>
    <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
      ЛИЦЕНЗИИ
    </h2>
    <section className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-2 gap-6 p-2">
      {[1, 2, 3, 4].map((_, index) => (
        <div
          key={index}
          className="cursor-pointer transition-transform duration-300 hover:scale-105"
        >
          <Skeleton
            variant="rectangular"
            className="w-full h-auto rounded-lg max-w-[380px] mx-auto"
            height={200}
          />
        </div>
      ))}
    </section>
  </>
);

const License = () => {
  const [licenses, setLicenses] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/licenses`
        );
        setLicenses(response.data);
      } catch (error) {
        console.error('Ошибка при получении лицензий', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLicenses();
  }, []);

  const openModal = (imagePath) => {
    setSelectedImage(imagePath);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
        ЛИЦЕНЗИИ
      </h2>
      {licenses.length ? (
        <section className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-2 gap-6 p-2">
          {licenses.map((license) => (
            <div
              key={license.id}
              className="cursor-pointer transition-transform duration-300 hover:scale-105"
              onClick={() => openModal(license.imagePath)}
            >
              <img
                src={`${process.env.REACT_APP_API_URL}/${license.imagePath}`}
                loading="lazy"
                alt={license.title}
                className="w-full h-auto rounded-lg max-w-[380px] mx-auto"
              />
            </div>
          ))}
        </section>
      ) : (
        <p className="w-full text-center">Лицензий пока не добавлено.</p>
      )}
      {selectedImage && (
        <Modal onClose={closeModal}>
          <img
            src={`${process.env.REACT_APP_API_URL}/${selectedImage}`}
            alt="License"
            className="w-full h-auto rounded-lg"
          />
        </Modal>
      )}
    </>
  );
};

export default License;
