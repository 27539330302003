// src/components/SmallAbout.jsx

import React from 'react';
import { useSelector } from 'react-redux';
import { responsiveAbout, CustomDot } from '../../subcomponents/CarouselParts';
import CheckIcon from '@mui/icons-material/Check';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Skeleton } from '@mui/material';

const Loader = () => (
  <section className="h-[640px] flex flex-col laptop:flex-row laptop:gap-4 justify-between pt-2 tablet:pt-6 laptop:mt-4">
    <div className="flex flex-col laptop:w-2/5">
      {/* Заголовок о клинике */}
      <Skeleton
        variant="text"
        width="50%"
        height={40}
        className="mb-4 laptop:mb-4"
      />
      {/* Название клиники */}
      <Skeleton
        variant="text"
        width="80%"
        height={30}
        className="mb-2 tablet:mb-4"
      />
      {/* Список фактов */}
      <article className="flex flex-col text-base gap-3 leading-[23px]">
        {[1, 2, 3].map((_, index) => (
          <div key={index} className="flex items-center">
            <Skeleton variant="circular" width={18} height={18} />
            <Skeleton variant="text" width="95%" height={40} className="ml-2" />
          </div>
        ))}
      </article>
      {/* Списки врачей и услуг */}
      <article className="flex flex-col tablet:gap-1 tablet:flex-row justify-between laptop:flex-col mt-4">
        <div className="w-full">
          <Skeleton
            variant="text"
            width="90%"
            height={30}
            className="font-medium mb-2"
          />
          <ul className="list-disc pl-6 pr-2">
            {[1, 2, 3].map((_, index) => (
              <li key={index}>
                <Skeleton variant="text" width="70%" height={25} />
              </li>
            ))}
          </ul>
        </div>
        <div className="mobile:mt-2 border-mediumgray tablet:mt-0 tablet:pl-4 w-full laptop:pl-0 laptop:ml-0 laptop:border-0 laptop:ml-0 laptop:pl-0 laptop:pt-2">
          <Skeleton
            variant="text"
            width="70%"
            height={30}
            className="font-medium mb-2"
          />
          <ul className="list-disc pl-6 pr-2">
            {[1, 2, 3].map((_, index) => (
              <li key={index}>
                <Skeleton variant="text" width="70%" height={25} />
              </li>
            ))}
          </ul>
        </div>
      </article>
    </div>
    {/* Лоадер для изображения или другой визуальной информации */}
    <div className="h-[450px] relative mt-4 laptop:mt-0 mb-2 flex-1 max-w-[100%] laptop:max-w-[60%] rounded-lg bg-navbargray">
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        className="rounded-lg"
      />
    </div>
  </section>
);

const SmallAbout = () => {
  const homepageData = useSelector((state) => state.homepage.data);
  const status = useSelector((state) => state.homepage.status);
  const error = useSelector((state) => state.homepage.error);

  if (status === 'loading') {
    return <Loader />;
  }
  if (status === 'failed') {
    return <p>Ошибка при загрузке данных: {error}</p>;
  }
  if (!homepageData) {
    return <Loader />;
  }

  const { title, photos, facts, availableDoctors, availableServices } =
    homepageData;

  return (
    <section className="flex flex-col laptop:flex-row laptop:gap-4 justify-between pt-2 tablet:pt-6 laptop:mt-4">
      <div className="flex flex-col laptop:w-2/5">
        <h2 className="mb-4 laptop:mb-4 text-2xl tablet:text-3xl font-semibold text-graytext ">
          О клинике
        </h2>
        <h4 className="mb-2 tablet:mb-4 text-sm">
          <span className="font-medium">{title}</span>
        </h4>
        <article className="flex flex-col text-base gap-3 leading-[23px]">
          {facts.map((fact, index) => (
            <p key={index}>
              <CheckIcon sx={{ width: '18px', height: '18px' }} />{' '}
              <span>{fact}</span>
            </p>
          ))}
          <article className="flex flex-col tablet:gap-1 tablet:flex-row justify-between laptop:flex-col">
            <div className="w-full">
              <p className="font-medium">В нашем центре ведут прием:</p>
              <ul className="list-disc pl-6 pr-2">
                {availableDoctors.map((doctor, index) => (
                  <li key={index}>{doctor}</li>
                ))}
              </ul>
            </div>
            <div className="mobile:mt-2 border-mediumgray tablet:mt-0 tablet:pl-4  w-full laptop:pl-0 laptop:ml-0 laptop:border-0 laptop:ml-0 laptop:pl-0 laptop:pt-2">
              <p className="font-medium">Производятся:</p>
              <ul className="list-disc pl-6 pr-2">
                {availableServices.map((service, index) => (
                  <li key={index}>{service}</li>
                ))}
              </ul>
            </div>
          </article>
        </article>
      </div>
      {/* Блок с слайдером */}
      <div className="relative mt-4 laptop:mt-0 mb-2 flex-1 max-w-[100%] laptop:max-w-[60%]">
        <Carousel
          responsive={responsiveAbout}
          arrows
          removeArrowOnDeviceType={['tablet', 'mobile']}
          swipeable
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={8000}
          keyBoardControl={true}
          containerClass="carousel-container"
          customDot={<CustomDot />}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          showDots
          slidesToSlide={1}
        >
          {photos.map((photo, idx) => (
            <div
              key={idx}
              className="rounded-lg mb-6 overflow-hidden
                h-[200px] mobile:h-[260px] tablet:h-[450px] laptop:h-[350px] desktop:h-[500px]"
            >
              <img
                loading="lazy"
                src={`${process.env.REACT_APP_API_URL}/${photo}`}
                alt="фотографии клиники"
                className="object-cover w-full h-full"
              />
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default SmallAbout;
