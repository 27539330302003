// src/components/Services.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
// import Loading from '../subcomponents/Loading'; // Убираем этот импорт

const Loader = () => (
  <>
    <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
      УСЛУГИ И ЦЕНЫ
    </h2>
    <ul className="gap-4 flex flex-col">
      {[1, 2, 3].map((_, index) => (
        <li key={index}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel-content-${index}`}
              id={`panel-header-${index}`}
            >
              <Skeleton variant="text" width="60%" height={30} />
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between font-semibold bg-bggray py-1 px-2 rounded">
                  <Skeleton variant="text" width="40%" height={20} />
                  <Skeleton variant="text" width="20%" height={20} />
                </div>
                {[1, 2, 3].map((__, idx) => (
                  <div
                    key={idx}
                    className={`rounded flex justify-between items-center py-[10px] px-4 font-medium ${
                      idx % 2 !== 0 ? 'bg-bggray' : 'bg-white'
                    }`}
                  >
                    <Skeleton variant="text" width="60%" height={20} />
                    <Skeleton variant="text" width="20%" height={20} />
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </li>
      ))}
    </ul>
  </>
);

const Services = () => {
  const [services, setServices] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true); // Добавляем состояние загрузки

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/services`
      );
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setLoading(false); // Устанавливаем loading в false после завершения запроса
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
        УСЛУГИ И ЦЕНЫ
      </h2>
      {services.length ? (
        <ul className="gap-4 flex flex-col">
          {services.map((service) => (
            <li key={service.id}>
              <Accordion
                expanded={expanded === service.id}
                onChange={handleAccordionChange(service.id)}
                sx={{
                  backgroundColor:
                    expanded === service.id ? '#308F8E' : '#f5f5f5',
                  color: expanded === service.id ? 'white' : 'black',
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMore
                      sx={{
                        color: expanded === service.id ? 'white' : 'black',
                      }}
                    />
                  }
                  aria-controls={`panel-content-${service.id}`}
                  id={`panel-header-${service.id}`}
                  sx={{
                    backgroundColor:
                      expanded === service.id ? '#308F8E' : 'inherit',
                  }}
                >
                  <h3
                    className={`font-semibold text-[16px] ${
                      expanded === service.id ? 'text-white' : 'text-graytext'
                    }`}
                  >
                    {service.category}
                  </h3>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: '8px',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '4px',
                    color: '#555555',
                    fontSize: '14px',
                  }}
                >
                  <div className="flex flex-col space-y-2">
                    <div className="flex justify-between font-semibold bg-bggray py-1 px-2 rounded">
                      <p>Наименование</p>
                      <p className="w-[100px] flex justify-end">Цена</p>
                    </div>
                    {service.services.map((serv, index) => (
                      <div
                        key={index}
                        className={`rounded flex justify-between items-center py-[10px] px-4 font-medium ${
                          index % 2 !== 0 ? 'bg-bggray' : 'bg-white'
                        }`}
                      >
                        <p>{serv[0]}</p>
                        <p className="w-[100px] flex justify-end">
                          {serv[1]} руб.
                        </p>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </li>
          ))}
        </ul>
      ) : (
        <p className="w-full text-center">Услуг пока не добавлено.</p>
      )}
    </>
  );
};

export default Services;
