// components/RegisterDoctor.jsx

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, TextField, IconButton } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import axios from 'axios';
import { fetchDoctors } from '../../../store/doctorSlice';
import InputFileUpload from '../../subcomponents/InputFileUpload';

const DAYS_OF_WEEK = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
];

const RegisterDoctor = () => {
  const dispatch = useDispatch();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [email, setEmail] = useState('');
  const [experience, setExperience] = useState(0);
  const [about, setAbout] = useState('');
  const [photo, setPhoto] = useState(null);
  const [education, setEducation] = useState('');
  const [schedule, setSchedule] = useState({});

  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleAddTimeSlot = (day) => {
    setSchedule((prevSchedule) => {
      const daySchedule = prevSchedule[day] || [];
      return {
        ...prevSchedule,
        [day]: [...daySchedule, { start: '', end: '' }],
      };
    });
  };

  const handleRemoveTimeSlot = (day, index) => {
    setSchedule((prevSchedule) => {
      const daySchedule = prevSchedule[day] || [];
      daySchedule.splice(index, 1);
      // Если после удаления массив пуст, удаляем день из расписания
      if (daySchedule.length === 0) {
        const { [day]: _, ...rest } = prevSchedule;
        return rest;
      }
      return {
        ...prevSchedule,
        [day]: [...daySchedule],
      };
    });
  };

  const handleScheduleChange = (day, index, field, value) => {
    setSchedule((prevSchedule) => {
      const daySchedule = prevSchedule[day] || [];
      const updatedTimeSlot = {
        ...daySchedule[index],
        [field]: value ? parseInt(value) : '',
      };
      daySchedule[index] = updatedTimeSlot;
      return {
        ...prevSchedule,
        [day]: [...daySchedule],
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Проверка на наличие необходимых полей
    if (
      !login ||
      !password ||
      !name ||
      !specialty ||
      !email ||
      !experience ||
      !education ||
      !photo
    ) {
      console.error('Все поля являются обязательными для заполнения');
      return;
    }

    const formData = new FormData();
    formData.append('login', login);
    formData.append('password', password);
    formData.append('name', name);
    formData.append('specialty', specialty);
    formData.append('email', email);
    formData.append('experience', experience);
    formData.append('about', about);
    formData.append('photo', photo);
    formData.append('education', education);
    formData.append('schedule', JSON.stringify(schedule));

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/doctors/register`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      dispatch(fetchDoctors());

      // Сброс полей формы
      setLogin('');
      setPassword('');
      setName('');
      setSpecialty('');
      setEmail('');
      setExperience(0);
      setAbout('');
      setPhoto(null);
      setEducation('');
      setSchedule({});
    } catch (error) {
      console.error(
        'Ошибка при регистрации доктора:',
        error.response?.data?.message
      );
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
        Заполните необходимые данные для добавления врача в БД
      </h2>
      <span className="font-medium my-1">
        Данные будут использоваться для входа в аккаунт врача:
      </span>
      <TextField
        required
        id="login"
        label="Логин"
        value={login}
        onChange={(e) => setLogin(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        required
        id="password"
        label="Пароль"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        required
        id="name"
        label="Ф.И.О"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ mb: 2 }}
      />
      <p className="font-medium my-1">
        При наличии более чем одной квалификации, указывайте через запятую ','
        для корректной работы категорий на странице "Врачи"
        <br />
        Например: гинеколог, окулист, хирург (без слова "врач").
      </p>
      <TextField
        required
        id="specialty"
        label="Специальность"
        value={specialty}
        onChange={(e) => setSpecialty(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        required
        id="email"
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        required
        id="experience"
        label="Опыт работы (только цифра)"
        type="number"
        value={experience}
        onChange={(e) => setExperience(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        required
        id="education"
        label="Образование"
        value={education}
        onChange={(e) => setEducation(e.target.value)}
        sx={{ mb: 2 }}
      />
      <textarea
        className="w-full p-[10px] mb-4 border-[1px] rounded-lg border-[#0000003B] hover:border-[#1976d2]"
        aria-label="about"
        placeholder="О специалисте"
        value={about}
        onChange={(e) => setAbout(e.target.value)}
      />

      <p className="font-medium my-4">
        От указанных часов работы зависят часы, в которые потенциальный пациент
        сможет записаться на прием.
      </p>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-semibold mb-2">
          Расписание работы:
        </label>
        {DAYS_OF_WEEK.map((day) => (
          <div key={day} className="mb-4">
            <div className="flex items-center mb-2">
              <span className="w-32">{day}:</span>
              <IconButton onClick={() => handleAddTimeSlot(day)}>
                <AddCircleOutline />
              </IconButton>
            </div>
            {(schedule[day] || []).map((timeSlot, index) => (
              <div key={index} className="flex items-center mb-2 ml-8">
                <TextField
                  label="От"
                  type="number"
                  value={timeSlot.start || ''}
                  onChange={(e) =>
                    handleScheduleChange(day, index, 'start', e.target.value)
                  }
                  InputProps={{ inputProps: { min: 0, max: 23 } }}
                  sx={{ mr: 2, width: '100px' }}
                />
                <TextField
                  label="До"
                  type="number"
                  value={timeSlot.end || ''}
                  onChange={(e) =>
                    handleScheduleChange(day, index, 'end', e.target.value)
                  }
                  InputProps={{ inputProps: { min: 0, max: 23 } }}
                  sx={{ mr: 2, width: '100px' }}
                />
                <IconButton
                  onClick={() => handleRemoveTimeSlot(day, index)}
                  color="error"
                >
                  <RemoveCircleOutline />
                </IconButton>
              </div>
            ))}
          </div>
        ))}
        <p className="text-sm text-gray-500 mt-2">
          Добавьте временные промежутки или оставьте пустым, если доктор не
          работает в этот день.
        </p>
      </div>

      <div>
        <InputFileUpload handleFileChange={handleFileChange} />
      </div>
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>
        Добавить врача
      </Button>
    </Box>
  );
};

export default RegisterDoctor;
