// src/components/Offers.jsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import calendarIcon from '../../assets/offers/calendar_icon.webp';
import { styled } from '@mui/material/styles';
import { Button, Skeleton } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';

const ColorButton = styled(Button)(({ theme, bgcolor }) => ({
  color: '#FFFF',
  backgroundColor: bgcolor,
  '&:hover': {
    backgroundColor: '#1976D2',
  },
  fontSize: 14,
  borderRadius: 999,
  border: '2px solid',
  borderColor: '#28926E',
}));

// Создаем компонент Loader
const Loader = () => (
  <>
    <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
      АКЦИИ
    </h2>
    <div className="flex flex-col gap-6 text-white">
      {[1, 2].map((_, index) => (
        <li
          key={index}
          className={`flex flex-col rounded-lg
                  px-2 py-2 gap-2
                  laptop:flex-row laptop:h-auto laptop:pt-2`}
        >
          {/* Изображение */}
          <div className="flex justify-center w-full laptop:flex-col laptop:justify-start laptop:max-w-[490px] desktop:max-w-full large:max-w-[630px]">
            <Skeleton
              variant="rectangular"
              width="100%"
              height={250}
              className="rounded-lg object-contain w-full h-auto max-w-full laptop:w-full"
            />
          </div>
          {/* Контент */}
          <div className="flex justify-center flex-col items-center tablet:px-6 w-full large:px-0 laptop:justify-between">
            {/* Заголовок для больших экранов */}
            <Skeleton
              variant="text"
              width="60%"
              height={50}
              className="hidden laptop:block mb-4 laptop:px-6 laptop:py-2"
            />
            {/* Описание */}
            <Skeleton
              variant="text"
              width="90%"
              height={60}
              className="text-lg mt-2 text-center desktop:mt-8 desktop:text-2xl laptop:px-6"
            />
            {/* Кнопки и информация */}
            <div className="w-full laptop:flex laptop:flex-row laptop:justify-between laptop:gap-4 desktop:mt-6 desktop:justify-center">
              {/* Кнопка "Записаться" */}
              <div className="flex justify-center laptop:justify-start">
                <Skeleton
                  variant="rectangular"
                  width={200}
                  height={50}
                  className="mt-6 w-[300px] laptop:w-[200px] rounded-full"
                />
              </div>
              {/* Информация о сроках акции */}
              <div className="flex justify-center">
                <Skeleton
                  variant="rectangular"
                  width={300}
                  height={66}
                  className="mt-6 rounded-full"
                />
              </div>
            </div>
          </div>
        </li>
      ))}
    </div>
  </>
);

const Offers = () => {
  const navigate = useNavigate();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true); // Добавляем состояние загрузки

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/offers`
        );
        setOffers(response.data);
      } catch (error) {
        console.error('Ошибка при получении акций', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
        АКЦИИ
      </h2>
      {offers.length ? (
        <div className="flex flex-col gap-6 text-white">
          {offers.map((offer, index) => {
            const isEven = index % 2 === 0;
            const bgColorClass = isEven ? 'bg-offerBlue' : 'bg-offerGreen';
            const buttonColor = isEven ? '#016B8D' : '#28926E';

            return (
              <li
                key={offer.id}
                className={`flex flex-col ${bgColorClass} rounded-lg
                px-2 py-2 gap-2
                laptop:flex-row laptop:h-auto laptop:pt-2 `}
              >
                <h3 className="pt-1 pb-3 tablet:py-2 tablet:pb-6 text-4xl text-center laptop:hidden">
                  {offer.title}
                </h3>

                <div className="flex justify-center w-full laptop:flex-col laptop:justify-start laptop:max-w-[490px] desktop:max-w-full large:max-w-[630px]">
                  <img
                    loading="lazy"
                    className="rounded-lg object-contain w-full h-auto max-w-full
                    laptop:w-full"
                    src={`${process.env.REACT_APP_API_URL}/${offer.image}`}
                    alt={offer.title}
                  />
                </div>

                <div className="flex justify-center flex-col items-center tablet:px-6 w-full large:px-0 laptop:justify-between">
                  <h3 className="hidden text-5xl text-center laptop:block mb-4 laptop:px-6 laptop:py-2">
                    {offer.title}
                  </h3>
                  <p className="text-lg mt-2 text-center desktop:mt-8 desktop:text-2xl laptop:px-6">
                    {offer.description}
                  </p>
                  <div
                    className="w-full
                    laptop:flex laptop:flex-row laptop:justify-between laptop:gap-4
                    desktop:mt-6
                    desktop:justify-center"
                  >
                    <div className="flex justify-center laptop:justify-start">
                      <div
                        className="flex justify-center bg-white rounded-full py-2 px-2 mt-6 w-[300px]
                        laptop:w-[200px]"
                      >
                        <ColorButton
                          onClick={() => navigate('/booking')}
                          endIcon={<AssignmentIcon />}
                          bgcolor={buttonColor}
                          variant=""
                          sx={{
                            height: '50px',
                            width: '100%',
                            border: 'green',
                          }}
                        >
                          <span>Записаться</span>
                        </ColorButton>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <div className="flex justify-start bg-white text-bgdarkgray rounded-full py-2 pl-2 mt-3 text-center w-[300px] h-[66px] laptop:mt-6">
                        <img
                          src={calendarIcon}
                          loading="lazy"
                          alt="сроки акции"
                          className="w-[50px] border-[2px] rounded-full border-themeColor"
                        />
                        <div className="ml-3">
                          <p>Акция действует</p>
                          <p className="flex gap-1">
                            <span>с</span>
                            <span>
                              {new Date(offer.startDate).toLocaleDateString()}
                            </span>
                            <span>по</span>
                            <span>
                              {new Date(offer.endDate).toLocaleDateString()}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </div>
      ) : (
        <p className="w-full text-center">Акций пока не добавлено.</p>
      )}
    </>
  );
};

export default Offers;
