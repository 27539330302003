// components/EditDoctorModal.jsx

import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import InputFileUpload from './InputFileUpload';

const DAYS_OF_WEEK = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
];

const EditDoctorModal = ({ open, onClose, doctor, onChange, onSave }) => {
  // Если doctor равен null или undefined, устанавливаем его как пустой объект
  doctor = doctor || {};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange({ ...doctor, [name]: value });
  };

  const handleAddTimeSlot = (day) => {
    const daySchedule = doctor.schedule?.[day] || [];
    const updatedSchedule = {
      ...doctor.schedule,
      [day]: [...daySchedule, { start: '', end: '' }],
    };
    onChange({ ...doctor, schedule: updatedSchedule });
  };

  const handleRemoveTimeSlot = (day, index) => {
    const daySchedule = doctor.schedule?.[day] || [];
    daySchedule.splice(index, 1);
    const updatedSchedule = { ...doctor.schedule, [day]: [...daySchedule] };
    // Если после удаления массив пуст, удаляем день из расписания
    if (daySchedule.length === 0) {
      delete updatedSchedule[day];
    }
    onChange({ ...doctor, schedule: updatedSchedule });
  };

  const handleScheduleChange = (day, index, field, value) => {
    const daySchedule = doctor.schedule?.[day] || [];
    const updatedTimeSlot = {
      ...daySchedule[index],
      [field]: value ? parseInt(value) : '',
    };
    daySchedule[index] = updatedTimeSlot;
    const updatedSchedule = { ...doctor.schedule, [day]: [...daySchedule] };
    onChange({ ...doctor, schedule: updatedSchedule });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onChange({ ...doctor, photo: file });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Редактировать Доктора</DialogTitle>
      <DialogContent>
        <div className="flex flex-col my-4">
          <TextField
            required
            id="login"
            label="Логин:"
            name="login"
            value={doctor?.login || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
          />
          <TextField
            required
            id="password"
            label="Пароль: (ОБНОВЛЯТЬ ПРИ ВНЕСЕНИИ ИЗМЕНЕНИЙ)"
            type="password"
            name="password"
            value={doctor?.password || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
          />
          <TextField
            required
            id="email"
            label="Email:"
            type="email"
            name="email"
            value={doctor?.email || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
          />
          <TextField
            required
            id="name"
            label="Ф.И.О:"
            name="name"
            value={doctor?.name || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
            variant="filled"
          />
          <TextField
            required
            id="specialty"
            label="Специальность:"
            name="specialty"
            value={doctor?.specialty || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
            variant="filled"
          />
          <TextField
            id="experience"
            label="Опыт работы: (только цифра)"
            type="number"
            name="experience"
            value={doctor?.experience || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
          />
          <TextField
            id="education"
            label="Образование:"
            name="education"
            value={doctor?.education || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
            variant="filled"
          />
          <div className="flex flex-col gap-1">
            <label htmlFor="about" className="text-sm font-medium text-gray-700">
              Произвольная информация (описание достижений и т.п.):
            </label>
            <textarea
              id="about"
              name="about"
              placeholder="О специалисте:"
              value={doctor?.about || ''}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 text-gray-700 bg-[#E8E8E8] border-b border-graytext rounded-t shadow-sm focus:outline-none focus:ring-0 focus:border-b-mainblue focus:border-b-2 transition duration-150 ease-in-out"
            />
          </div>

          <div className="my-4">
            <label className="block text-gray-700 text-sm font-semibold mb-2">
              Расписание работы:
            </label>
            {DAYS_OF_WEEK.map((day) => (
              <div key={day} className="mb-4">
                <div className="flex items-center mb-2">
                  <span className="w-32">{day}:</span>
                  <IconButton onClick={() => handleAddTimeSlot(day)}>
                    <AddCircleOutline />
                  </IconButton>
                </div>
                {(doctor.schedule?.[day] || []).map((timeSlot, index) => (
                  <div key={index} className="flex items-center mb-2 ml-8">
                    <TextField
                      label="От"
                      type="number"
                      value={timeSlot.start || ''}
                      onChange={(e) =>
                        handleScheduleChange(day, index, 'start', e.target.value)
                      }
                      InputProps={{ inputProps: { min: 0, max: 23 } }}
                      sx={{ mr: 2, width: '100px' }}
                    />
                    <TextField
                      label="До"
                      type="number"
                      value={timeSlot.end || ''}
                      onChange={(e) =>
                        handleScheduleChange(day, index, 'end', e.target.value)
                      }
                      InputProps={{ inputProps: { min: 0, max: 23 } }}
                      sx={{ mr: 2, width: '100px' }}
                    />
                    <IconButton
                      onClick={() => handleRemoveTimeSlot(day, index)}
                      color="error"
                    >
                      <RemoveCircleOutline />
                    </IconButton>
                  </div>
                ))}
              </div>
            ))}
            <p className="text-sm text-gray-500 mt-2">
              Добавьте временные промежутки или оставьте пустым, если доктор не работает в этот день.
            </p>
          </div>
          <div>
            <InputFileUpload handleFileChange={handleFileChange} />
          </div>
          <h2 className="text-center py-4 text-red">
            ОБНОВИТЕ ПАРОЛЬ ПРИ ВНЕСЕНИИ ИЗМЕНЕНИЙ
          </h2>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Отмена
        </Button>
        <Button onClick={onSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDoctorModal;
